import React, { Component } from "react"
import Back from "../back-nav/back-nav"

import "./content-ursonate.css"
import "../../css/global.css"
import "../../css/reset.css"
import "../../css/type.css"

class UrsonateContent extends Component {
  render() {
    return (
      <div>
        <main className="ursonate">
          <div className="ursonate-cotent__hero-text">
            <p>f&uuml;mmsb&ouml;</p>
            <p>
              <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
              b&ouml;w&ouml;r&ouml;
            </p>
            <p>f&uuml;mmsb&ouml;w&ouml;</p>
            <p>
              <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
              b&ouml;w&ouml;r&ouml;t&auml;&auml;
            </p>
            <p>f&uuml;mmsb&ouml;w&ouml;t&auml;&auml;</p>
            <p>
              <span>&nbsp; &nbsp; &nbsp; &nbsp;</span>
              b&ouml;w&ouml;r&ouml;t&auml;&auml;z&auml;&auml;
            </p>
            <p>f&uuml;mmsb&ouml;w&ouml;t&auml;&auml;z&auml;&auml;</p>
            <p>
              <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>
              b&ouml;w&ouml;r&ouml;t&auml;&auml;z&auml;&auml;Uu
            </p>
            <p>f&uuml;mmsb&ouml;w&ouml;t&auml;&auml;z&auml;&auml;Uu</p>
            <p>
              <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
              b&ouml;w&ouml;r&ouml;t&auml;&auml;z&auml;&auml;Uu p&ouml;
            </p>
            <p>f&uuml;mmsb&ouml;w&ouml;t&auml;&auml;z&auml;&auml;Uu p&ouml;</p>
            <p>
              <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
              b&ouml;w&ouml;r&ouml;t&auml;&auml;z&auml;&auml;Uu p&ouml;g&ouml;
            </p>
            <p>
              f&uuml;mmsb&ouml;w&ouml;t&auml;&auml;z&auml;&auml;Uu
              p&ouml;g&ouml;
            </p>
            <p>
              <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
              b&ouml;w&ouml;r&ouml;t&auml;&auml;z&auml;&auml;Uu p&ouml;giff
            </p>
            <p>
              f&uuml;mmsb&ouml;w&ouml;t&auml;&auml;z&auml;&auml;Uu p&ouml;giff
            </p>
          </div>
          <div className="ursonate-content__poem">
            <div className="ursonate-content__poem__text">
              <p>rakete rinnzekete</p>
              <p>
                rakete rinnzekete
                <span>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                </span>
              </p>
              <p>rakete rinnzekete</p>
              <p>rakete rinnzekete</p>
              <p>rakete rinnzekete</p>
              <p>rakete rinnzekete</p>
              <p>
                {" "}
                <br />
              </p>
              <p>beeeeeeeeeeeeeeeee bo faux</p>
              <p>
                {" "}
                <br />
              </p>
              <p>scurry the bay bay</p>
              <p>Rinse zeek eet baby</p>
              <p>rockabye baby</p>
              <p>scurry the bay bay endsssssss</p>
              <p>
                {" "}
                <br />
              </p>
              <p>Rinse zeek eet baby ends.</p>
              <p>
                {" "}
                <br />
              </p>
              <p>rockabye baby nnz krrrr</p>
              <p>scurry the bay bay nnz krrrrr</p>
              <p>rinz eek it baby ends kerrrrr</p>
              <p>rockabye baby nnz krrrr mooo</p>
              <p>scurry the bay bay nnz krrrrr muuuuu</p>
              <p>rinz eek it baby ends kerrrrr moo</p>
              <p>rockabye baby</p>
              <p>rockabye baby rockabye baby</p>
              <p>rocket baby and scurry</p>
              <p>
                {" "}
                <br />
              </p>
              <p>foods baltazar.</p>
              <p>
                {" "}
                <br />
              </p>
              <p>
                dilla are bg Bo deal are bebo funs both are be whole foods volo.
              </p>
              <p>
                {" "}
                <br />
              </p>
              <p>Ooooooooooooooooooooooo</p>
              <p>
                does a den NRE2 to June carter bbn scare moon zoo Annie&#39;s
                no.
              </p>
              <p>
                {" "}
                <br />
              </p>
              <p>track it to baby, track it to bay bay.</p>
              <p>
                {" "}
                <br />
              </p>
              <p>
                rump teeth to Zen you Annie&#39;s new moon continue and ZZ New
                York.
              </p>
              <p>
                {" "}
                <br />
              </p>
              <p>
                look at the baby rocket bezos saying saying whole foods bowl
                full the zoo ETV be funs.
              </p>
              <p>
                {" "}
                <br />
              </p>
              <p>
                racketeering ditty racketeering details racketeering dirty
                rocket hitting details racketeering ditty racketeering details
                racketeering dirty rocket hitting details
              </p>
              <p>
                {" "}
                <br />
              </p>
              <p>who is bobo world road.</p>
              <p>
                {" "}
                <br />
              </p>
              <p>foods ball rota funs bowtie a bowtie.</p>
              <p>
                {" "}
                <br />
              </p>
              <p>
                who wrote Tarzan, who is bolt, who wrote has a pogo pogo pogo,
                who is a pogo pogifffffff.
              </p>
              <p>
                {" "}
                <br />
              </p>
              <p>
                baby I like the beast baby I like the beast baby I like the
                beast baby I like the beast,
              </p>
              <p>
                {" "}
                <br />
              </p>
              <p>news but what time is it.</p>
              <p>&lt;/prosody&gt;</p>
              <p>
                {" "}
                <br />
              </p>
              <p>rakete rinnzekete</p>
              <p>
                rakete rinnzekete
                <span>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                </span>
              </p>
              <p>rakete rinnzekete</p>
              <p>rakete rinnzekete</p>
              <p>rakete rinnzekete</p>
              <p>rakete rinnzekete</p>
              <p>
                {" "}
                <br />
              </p>
              <p>beeeeeeeeeeeeeeeee bo faux</p>
              <p>
                {" "}
                <br />
              </p>
              <p>scurry the bay bay</p>
              <p>Rinse zeek eet baby</p>
              <p>rockabye baby</p>
              <p>scurry the bay bay endsssssss</p>
              <p>
                {" "}
                <br />
              </p>
              <p>Rinse zeek eet baby ends.</p>
              <p>
                {" "}
                <br />
              </p>
              <p>rockabye baby nnz krrrr</p>
              <p>scurry the bay bay nnz krrrrr</p>
              <p>rinz eek it baby ends kerrrrr</p>
              <p>rockabye baby nnz krrrr mooo</p>
              <p>scurry the bay bay nnz krrrrr muuuuu</p>
              <p>rinz eek it baby ends kerrrrr moo</p>
              <p>rockabye baby</p>
              <p>rockabye baby rockabye baby</p>
              <p>rocket baby and scurry</p>
              <p>
                {" "}
                <br />
              </p>
              <p>foods baltazar.</p>
              <p>
                {" "}
                <br />
              </p>
              <p>
                dilla are bg Bo deal are bebo funs both are be whole foods volo.
              </p>
              <p>
                {" "}
                <br />
              </p>
              <p>Ooooooooooooooooooooooo</p>
              <p>
                does a den NRE2 to June carter bbn scare moon zoo Annie&#39;s
                no.
              </p>
              <p>
                {" "}
                <br />
              </p>
              <p>track it to baby, track it to bay bay.</p>
              <p>
                {" "}
                <br />
              </p>
              <p>
                rump teeth to Zen you Annie&#39;s new moon continue and ZZ New
                York.
              </p>
              <p>
                {" "}
                <br />
              </p>
              <p>
                look at the baby rocket bezos saying saying whole foods bowl
                full the zoo ETV be funs.
              </p>
              <p>
                {" "}
                <br />
              </p>
              <p>
                racketeering ditty racketeering details racketeering dirty
                rocket hitting details racketeering ditty racketeering details
                racketeering dirty rocket hitting details
              </p>
              <p>
                {" "}
                <br />
              </p>
              <p>who is bobo world road.</p>
              <p>
                {" "}
                <br />
              </p>
              <p>foods ball rota funs bowtie a bowtie.</p>
              <p>
                {" "}
                <br />
              </p>
              <p>
                who wrote Tarzan, who is bolt, who wrote has a pogo pogo pogo,
                who is a pogo pogifffffff.
              </p>
              <p>
                {" "}
                <br />
              </p>
              <p>
                baby I like the beast baby I like the beast baby I like the
                beast baby I like the beast,
              </p>
              <p>
                {" "}
                <br />
              </p>
              <p>news but what time is it.</p>
            </div>
          </div>
          <div className="ursonate-content__ending-text">
            <p>Oooooooooooooooooooooooo, &nbsp; &nbsp;6</p>
            <p>dll rrrrr beeeee b&ouml;</p>
            <p>
              dll rrrrr beeeee b&ouml; f&uuml;mms b&ouml;,
              <span>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;
              </span>
              (A)
            </p>
            <p>
              <span>&nbsp; &nbsp; &nbsp;</span>
              rrrrr beeeee b&ouml; f&uuml;mms b&ouml; w&ouml;,
            </p>
            <p>
              <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
              beeeee b&ouml; f&uuml;mms b&ouml; w&ouml; t&auml;&auml;,
            </p>
            <p>
              <span>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp;&nbsp;
              </span>
              b&ouml; f&uuml;mms b&ouml; w&ouml; t&auml;&auml; z&auml;&auml;,
            </p>
            <p>
              <span>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              </span>
              f&uuml;mms b&ouml; w&ouml; t&auml;&auml; z&auml;&auml; Uu: &nbsp;
              &nbsp;5z
            </p>
          </div>
        </main>
      </div>
    )
  }
}
export default UrsonateContent
